<template>
  <div>
    <div class="text-h4 text-bold q-pb-md">
      {{ $t('label.dashboard') }}
    </div>
    <div>
      <PageTabs/>
    </div>
    <div class="q-py-lg row">
      <div class="col">
        <slot/>
      </div>
      <Banner
        v-if="showBanner"
        :type="ENUMS.BANNER_TYPES.SMALL_BANNER"
        :location="ENUMS.BANNER_LOCATIONS.DASHBOARD"
        class="q-ml-lg col-shrink"
      />
    </div>
  </div>
</template>

<script>
import Banner from 'common/components/Banner';
import PageTabs from 'src/components/PageTabs';

export default {
  name: 'DashboardPageLayout',
  components: {
    Banner,
    PageTabs,
  },
  computed: {
    showBanner() {
      return ![ENUMS.ROUTES.DASHBOARD.AFFILIATES].includes(this.$route.name);
    },
  },
};
</script>
