<template>
  <DashboardPageLayout>
    <router-view/>
  </DashboardPageLayout>
</template>

<script>
import DashboardPageLayout from 'layouts/DashboardPageLayout';

export default {
  name: 'IndexPage',
  components: {
    DashboardPageLayout,
  },
};
</script>
