<template>
  <div
    v-if="account"
    class="row border-bottom"
  >
    <div class="col flex items-center">
      <AppTabs v-model="currentTab">
        <q-route-tab
          v-for="tab in tabs"
          :key="tab.label"
          :ripple="false"
          :name="tab.label"
          :label="tab.label"
          :to="{ name: tab.route }"
          :class="['no-hover', { separator: tab.separator }]"
        />
      </AppTabs>
    </div>
    <div class="col flex items-center justify-end">
      <div class="flex items-center">
        <AppButton
          v-if="showFormButton"
          :disable="!account.capabilities.canManageForm"
          :label="$t('button.createNewForm')"
          :icon-right="account.capabilities.canManageForm ? 'fl:plus' : 'fa fa-lock'"
          text-color="dark"
          icon-right-size="21px"
          dense
          type="submit"
          align="between"
          @click="openNewForm"
        />
        <AppButton
          v-else-if="showPageButton"
          :disable="!account.capabilities.canManagePage"
          :label="$t('button.createPage')"
          :icon-right="account.capabilities.canManagePage ? 'fl:plus' : 'fa fa-lock'"
          text-color="dark"
          icon-right-size="21px"
          dense
          type="submit"
          align="between"
          @click="goToPageTemplates"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppTabs from 'common/components/AppTabs';
import AppButton from 'common/components/buttons/AppButton';
import { createPageMixin } from 'common/mixins';
import { createFormMixin } from 'src/mixins';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'PageTabs',
  components: { AppTabs, AppButton },
  mixins: [createFormMixin, createPageMixin],
  data() {
    return {
      currentTab: 'Dashboard',
    };
  },
  computed: {
    ...mapGetters('app', ['isActiveAccount']),
    ...mapState('app', ['account']),
    showFormButton() {
      return this.$route.name === ENUMS.ROUTES.DASHBOARD.INDEX;
    },
    showPageButton() {
      return this.$route.name === ENUMS.ROUTES.DASHBOARD.PAGES;
    },
    tabs() {
      const {
        canManageForm, canManageSMTP, canHasAffiliateUsers, canManagePage,
      } = this.account.capabilities;
      return [
        {
          label: this.$t('label.forms'),
          isActive: canManageForm,
          route: ENUMS.ROUTES.DASHBOARD.INDEX,
        },
        {
          label: this.$t('label.affiliatePages'),
          isActive: canManagePage,
          route: ENUMS.ROUTES.DASHBOARD.PAGES,
          separator: true,
        },
        {
          label: this.$t('label.myAffiliates'),
          isActive: canHasAffiliateUsers,
          route: ENUMS.ROUTES.DASHBOARD.AFFILIATES,
          separator: true,
        },
        {
          label: this.$t('label.autoresponders'),
          isActive: this.isActiveAccount,
          route: ENUMS.ROUTES.DASHBOARD.AUTORESPONDERS,
        },
        {
          label: this.$t('label.webinarProviders'),
          isActive: canManageForm,
          route: ENUMS.ROUTES.DASHBOARD.WEBINARS,
        },
        {
          label: this.$t('label.smtp'),
          isActive: canManageSMTP,
          route: ENUMS.ROUTES.DASHBOARD.SMTP,
        },
      ].filter((tab) => tab.isActive);
    },
  },
};
</script>

<style lang="scss">
.separator {
  position: relative;
  &:after {
    background: $grey-7;
    content: '';
    height: 16px;
    position: absolute;
    right: -16px;
    width: 1px;
  }
}
</style>
